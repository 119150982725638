<template>
  <v-card v-if="taxonomy">
    <v-card-text>
      <Taxonomy :taxonomy="taxonomy" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { formatTaxonomy } from "@/lib/tools";
const page = inject("page");
const type = unref(page).typename;

const data = unref(page)?.rels;

const emit = defineEmits(["nodata"]);
const props = defineProps({
  defaults: {
    type: Boolean,
    required: false,
    default: false,
  },
  keys: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const { defaults, keys } = toRefs(props);
const taxonomy = ref(null);

if (defaults.value === false && keys.value.length === 0) {
  emit("nodata");
} else {
  const defaultKeys =
    type === "BrandReview"
      ? ["Popularity", "Welcome Bonus", "Software", "Support options", "Currencies"]
      : type === "SlotsGameReview"
      ? ["Popularity", "Software", "Themes", "Type", "Paylines", "Bonus round", "Max Bet"]
      : [];
  const piker = defaults.value ? defaultKeys : keys.value;
  taxonomy.value = _.pick(data, piker);
}
</script>
